import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { GreenButton } from "../elements/buttons"
import { MainTitle } from "../elements/titles"

const ContentDiv = styled.div`
  max-width: 1200px;
  justify-content: space-evenly;
  margin: auto;
  display: flex;
  flex-flow: wrap;
  padding: 1rem 0 5rem 0;

  .para-y-padding {
    padding: 2rem 0;
  }

  .leftbox {
    flex: 2;
    h2 {
      color: white;
      font-size: 4rem;
      text-transform: uppercase;
      text-shadow: #000000 0 6px 12px;
      padding: 3rem 0 1rem 0;
    }
    h3 {
      color: #a9ff00;
      font-size: 2.6rem;
      padding: 0 0 2rem 0;
      text-shadow: #000000 0 6px 12px;
    }
    p {
      color: white;
      font-size: 1.1rem;
      text-shadow: #000000 1px 2px 2px;
    }
  }
  .rightbox {
    flex: 3;
    padding-top: 1rem;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  @media (max-width: 1135px) {
    flex-direction: column;
    padding: 0 2rem 2rem 2rem;

    .leftbox {
      width: 100%;
    }

    .rightbox {
      width: 100%;
    }
  }
`

const GetNoticedText = () => {
  const data = useStaticQuery(graphql`
    query {
      Image: file(relativePath: { eq: "home-showcase.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <ContentDiv>
      <div className="leftbox">
        <MainTitle>Get Noticed</MainTitle>
        <h3>
          Fleet Graphics <br /> Embroidery <br /> Screen Printing <br /> Signs &
          Banners
        </h3>
        <p>
          We cater to your graphic and print needs by offering <br />{" "}
          quality-focused, service-oriented products and services.
        </p>
        <p className="para-y-padding">
          Our professional team works closely with you to maximize the
          creativity and visual impact of your Fleet Graphics, Embroidery
          projects, Screen Printing, or Signs & Banners.
        </p>
        <GreenButton>
          <Link to="services">Learn More</Link>
        </GreenButton>
      </div>
      <div className="rightbox">
        <Img fluid={data.Image.childImageSharp.fluid} />
      </div>
    </ContentDiv>
  )
}

export default GetNoticedText
