import React from "react"
import styled from "styled-components"
import { MainBlueTitle, GreenTitle, LightBlueTitle } from "../elements/titles"
import ContactForm from "../ContactForm"
import Container from "../utilities/wrapper"

const ContentDiv = styled.section`
  padding: 5rem 0;
  min-height: 100vh;

  .title-wrapper h2 {
    text-align: center;
    padding-bottom: 4rem;
  }

  .flex-wrapper {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .title-y-padding {
    padding: 1rem 0 2rem 0;
  }

  .leftbox {
    text-align: center;
    flex-basis: 500px;
  }

  .leftbox p {
    padding: 0.25rem 0;
    color: #08297e;
    text-align: left;
  }
  .rightbox {
    flex-basis: 400px;
  }

  @media (max-width: 1135px) {
    .flex-wrapper {
      padding: 1rem 2rem;
    }

    .title-wrapper h2 {
      font-size: 2.75rem;
    }

    .rightbox {
      transform: scale(0.85);
    }
  }
`

const ConversationContent = () => {
  return (
    <ContentDiv>
      <Container>
        <div className="title-wrapper">
          <MainBlueTitle size="3.75rem">
            LET’S START A CONVERSATION
          </MainBlueTitle>
        </div>
        <div className="flex-wrapper">
          <div className="leftbox">
            <LightBlueTitle size="2.75rem">Request A Quote</LightBlueTitle>

            <GreenTitle size="2.3rem" className="title-y-padding">
              <a href="tel:+19153077231">(915) 307-7231</a>
            </GreenTitle>
            <p>
              We’ve been serving El paso, Texas since 2009. We focus on building
              a better bottom line for our customers. We pride ourselves on the
              quality of the finished product and the speed in which we are able
              to accomplish it.
            </p>
            <br />
            <p>
              We provide a <strong>free consultation</strong> and let you know
              how we can customize our services for your specific needs.
            </p>
            <br />
            <p>
              We are passionate about our work and consistently develop
              innovative and attractive designs. We’ll take care of your
              graphics and print needs from start to finish using only the
              highest quality materials.
            </p>
          </div>
          <div className="rightbox">
            <ContactForm />
          </div>
        </div>
      </Container>
    </ContentDiv>
  )
}

export default ConversationContent
