import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const VendorLogos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
  flex-flow: wrap;

  & > div {
    margin: 0 0.75rem;
  }
`

const Logos = () => {
  const data = useStaticQuery(graphql`
    query {
      threeM: file(relativePath: { eq: "logos/3M.png" }) {
        childImageSharp {
          fixed(width: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      arlon: file(relativePath: { eq: "logos/Arlon.png" }) {
        childImageSharp {
          fixed(width: 110) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      avery: file(relativePath: { eq: "logos/Avery.png" }) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      orcal: file(relativePath: { eq: "logos/Orcal.png" }) {
        childImageSharp {
          fixed(width: 110) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <VendorLogos>
      <div>
        <Img fixed={data.threeM.childImageSharp.fixed} />
      </div>
      <div>
        <Img fixed={data.orcal.childImageSharp.fixed} />
      </div>
      <div>
        <Img fixed={data.arlon.childImageSharp.fixed} />
      </div>
      <div>
        <Img fixed={data.avery.childImageSharp.fixed} />
      </div>
    </VendorLogos>
  )
}

export default Logos
