import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { MainBlueTitle } from "../elements/titles"
import Container from "../utilities/wrapper"

const Content = styled.section`
  padding: 5rem 0;

  .others-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: center;
    color: #00000;
  }

  .others-icon-wrapper {
    text-align: center;
  }

  .others-item {
    flex-basis: 280px;
    /* width: 280px; */
    margin: 0 2rem;
    text-align: left;
    align-self: flex-start;
  }

  .others-item h4 {
    color: #08297e;
    font-size: 1.5rem;
    padding: 2rem 0;
    text-align: center;
  }

  .title-padding {
    text-align: center;
    padding-bottom: 3rem;
  }

  @media (max-width: 535px) {
  }
`

const WhatOthersSayingContent = () => {
  const data = useStaticQuery(graphql`
    query {
      othersIcon: file(relativePath: { eq: "Other.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <Content>
      <Container>
        <div className="title-padding">
          <MainBlueTitle size="3.2rem">
            What Others are Saying About Us
          </MainBlueTitle>
        </div>
        <div className="others-icon-wrapper">
          <Img fixed={data.othersIcon.childImageSharp.fixed} />
        </div>
        <div className="others-wrapper">
          <div className="others-item">
            <h4>Gary B.</h4>
            <p>
              “They were very friendly and had fast service and great
              product….thanks again”
            </p>
          </div>
          <div className="others-item">
            <h4>James W.</h4>
            <p>
              “Freddy has done amazing work for us at El Paso Dent Repair. Us
              military veteran, small business. Meets and exceeds deadline and
              designs. Van wrap, window frontage to business cards, uniforms…you
              name it. Great quality, excellent service and respectable pricing.
              Couldn’t be happier. Ty Freddy!”
            </p>
          </div>
          <div className="others-item">
            <h4>David M.</h4>
            <p>
              “Very nice and friendly service, will be using there service
              again”
            </p>
          </div>
        </div>
      </Container>
    </Content>
  )
}

export default WhatOthersSayingContent
