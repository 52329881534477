import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { BlueButton } from "../elements/buttons"
import { MainBlueTitle } from "../elements/titles"

const ServicesContentDiv = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 50vh;
  padding: 5rem 0;

  .services-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .service-item {
    margin: 0 2rem;
    text-align: center;
  }

  .service-item h3 {
    color: #08297e;
    font-size: 1.3rem;
    padding: 1rem 0;
  }

  .button-padding {
    padding-top: 2rem;
  }
  .title-padding {
    padding-bottom: 2rem;
  }

  @media (max-width: 535px) {
  }
`

const ServicesContent = () => {
  const data = useStaticQuery(graphql`
    query {
      Fleet: file(relativePath: { eq: "services/FleetGraphics.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      VehicleWraps: file(relativePath: { eq: "services/VehicleWraps.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      ScreenPrinting: file(
        relativePath: { eq: "services/ScreenPrinting.png" }
      ) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      Embroidery: file(relativePath: { eq: "services/Embroidery.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      BannersMore: file(relativePath: { eq: "services/BannersMore.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <ServicesContentDiv>
      <div className="title-padding">
        <MainBlueTitle>Services</MainBlueTitle>
      </div>
      <div className="services-wrapper">
        <div className="service-item">
          <Img fixed={data.Fleet.childImageSharp.fixed} />
          <h3>Fleet Graphics</h3>
        </div>
        <div className="service-item">
          <Img fixed={data.VehicleWraps.childImageSharp.fixed} />
          <h3>Vehicle Wraps</h3>
        </div>
        <div className="service-item">
          <Img fixed={data.ScreenPrinting.childImageSharp.fixed} />
          <h3>Screen Printing</h3>
        </div>
        <div className="service-item">
          <Img fixed={data.Embroidery.childImageSharp.fixed} />
          <h3>Embroidery</h3>
        </div>
        <div className="service-item">
          <Img fixed={data.BannersMore.childImageSharp.fixed} />
          <h3>Banner & More</h3>
        </div>
      </div>

      <div className="button-padding">
        <Link to="services">
          <BlueButton>Learn More</BlueButton>
        </Link>
      </div>
    </ServicesContentDiv>
  )
}

export default ServicesContent
