import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GetNoticed from "../components/homepage/GetNoticed"
import Logos from "../components/homepage/Logos"
import ServicesBG from "../components/homepage/ServicesBG"
import WhatOthersSaying from "../components/homepage/WhatOthersSaying"
import StartConversation from "../components/homepage/StartConversation"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <GetNoticed />
    <StartConversation />
    <ServicesBG />
    <Logos />
    <WhatOthersSaying />
  </Layout>
)

export default IndexPage
